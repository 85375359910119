.printable-input {
  border: none;
  padding: 2px 8px;
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.05);
  font-size: 12px;
}

.printable-input :focus {
  border: none;
}

.printable-input-label {
  font-size: 12px;
  font-weight: 500;
}

.column-title {
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  color: rgba(0, 0, 0, 0.4);
}

.invoice-item-title {
  color: #333;
  text-align: center;
  border: none;
  padding-left: 4px;
  padding-right: 4px;
  background: transparent;
}